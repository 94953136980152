import Api from '@/plugins/axios/axios'

export default {
    PostVote(data){
        return Api().post('MakeVote', data)
    },
    GetVoteResults() {
        return Api().get('GetVoteResults')
    },
    CheckPassword(data){
        const formData = new FormData();
        formData.append('password', data);
        return Api().post("PrePasswordCheck", formData)
    },
    GetProposalNames(){
        return Api().get('GetProposalNames')
    }
}