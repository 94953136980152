<script setup>
  import LoginForm from '../components/LoginForm'
</script>

<template>
  <LoginForm />
</template>

<script>
  export default {
    name: "LoginView"
  }
</script>
