export { camel2title, switchName}

const camel2title = (str) => str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()


const switchName = (stepName) => {
    let result = ""
    switch (stepName){
        case "PersonalData":
            result = "Dane Osobowe"
            break;
        case "CheckBoxes":
            result = "Propozycje"
            break;
        case "TermsOfUse":
            result = "Regulamin"
            break;
        default:
            result = ""
            break;
    }
    return result;
}

