<template>
  <h1>Not Found</h1>
</template>

<script>
export default {}
</script>

<style scoped>

</style>